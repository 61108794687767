import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const JobsPage = () => {
  const [jobs, setJobs] = useState([]); // State to store job data
  const [loading, setLoading] = useState(true); // State to show loading indicator
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch jobs from the backend
    const fetchJobs = async () => {
      try {
        let url = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`;
        const response = await axios.get(`http://${url}/get-jobs`); 
        setJobs(response.data); // Update the jobs state directly with the array
        setLoading(false);
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setLoading(false);
      }
    };

    fetchJobs(); // Call fetchJobs on component mount
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>; // Show loading state
  }

  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: 2 }}>Jobs</Typography>
      <TextField label="Search" variant="outlined" sx={{ marginBottom: 2, width: '100%' }} />
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Role</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
            <TableRow 
                key={job.listingId} 
                hover 
                onClick={() => navigate(`/jobs/${job.listingId}`)}
                style={{ cursor: 'pointer' }}
            >
                <TableCell>{job.title || "N/A"}</TableCell>
                <TableCell>{job.status || "N/A"}</TableCell>
                <TableCell>{job.rate || "-"}</TableCell>
                <TableCell>{job.type || "N/A"}</TableCell>
                <TableCell>{job.location || "N/A"}</TableCell>
            </TableRow>
            ))}
        </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default JobsPage;




