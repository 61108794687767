import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Typography, CircularProgress } from '@mui/material';

const JobDetailsPage = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await axios.get(`http://localhost:3000/get-job/${id}`);
        setJob(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching job details:", error);
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  if (!job) {
    return <Typography>Job not found</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4">{job.title}</Typography>
      <Typography variant="subtitle1">Status: {job.status}</Typography>
      <Typography variant="subtitle1">Rate: {job.rate}</Typography>
      <Typography variant="subtitle1">Type: {job.type}</Typography>
      <Typography variant="subtitle1">Location: {job.location}</Typography>
      <Typography variant="body1" sx={{ marginTop: 2 }}>
        {job.description || "No description available"}
      </Typography>
    </Box>
  );
};

export default JobDetailsPage;
