import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Typography, Collapse, Box } from '@mui/material';
import { People, Work, ListAlt, AccountCircle, Settings, Person, Logout } from '@mui/icons-material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ListIcon from '@mui/icons-material/List';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = ({ handleLogout }) => {
  const [open, setOpen] = useState(true);
  const [expandMenu, setExpandMenu] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenuClick = () => {
    setExpandMenu(!expandMenu);
  };

  const getItemStyle = (path) => ({
    color: location.pathname === path ? 'var(--deepPurple-700, #512DA8)' : 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    backgroundColor: location.pathname === path ? 'var(--deepPurple-50, #EDE7F6)' : 'transparent',
    borderRadius: '8px',
    padding: '10px',
  });

  const getIconStyle = (path) => ({
    color: location.pathname === path ? 'var(--deepPurple-700, #512DA8)' : 'rgba(0, 0, 0, 0.56)',
    fontSize: '24px',
    fontWeight: 400,
  });

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? 240 : 60,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? 240 : 60,
          transition: 'width 0.3s',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
    >
      {/* Top Section */}
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="10px"
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        >
          {open && (
            <Typography
              variant="h6"
              sx={{
                paddingLeft: '10px',
                flexGrow: 1,
                color: 'rgba(0, 0, 0, 0.87)',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '0.15px',
              }}
            >
              The Growth Spark
            </Typography>
          )}
          <IconButton onClick={toggleDrawer} sx={{ transform: open ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s' }}>
            <MenuOpenIcon style={{ fontSize: 24, color: 'rgba(0, 0, 0, 0.56)' }} />
          </IconButton>
        </Box>

        <List>
          <ListItem button component={Link} to="/jobs" selected={location.pathname === '/jobs'} sx={getItemStyle('/jobs')}>
            <ListItemIcon>
              <Work style={getIconStyle('/jobs')} />
            </ListItemIcon>
            {open && <ListItemText primary="Jobs" primaryTypographyProps={{ sx: getItemStyle('/jobs') }} />}
          </ListItem>

          <ListItem button component={Link} to="/candidates" selected={location.pathname === '/candidates'} sx={getItemStyle('/candidates')}>
            <ListItemIcon>
              <People style={getIconStyle('/candidates')} />
            </ListItemIcon>
            {open && <ListItemText primary="Candidates" primaryTypographyProps={{ sx: getItemStyle('/candidates') }} />}
          </ListItem>

          <ListItem button component={Link} to="/personas" selected={location.pathname === '/personas'} sx={getItemStyle('/personas')}>
            <ListItemIcon>
              <ListIcon style={getIconStyle('/personas')} />
            </ListItemIcon>
            {open && <ListItemText primary="Personas" primaryTypographyProps={{ sx: getItemStyle('/personas') }} />}
          </ListItem>
        </List>
      </Box>

      {/* Bottom Section - Profile */}
      <Box sx={{ paddingBottom: '10px' }}>
        <ListItem button onClick={handleMenuClick} sx={{ padding: '10px', color: 'rgba(0, 0, 0, 0.56)' }}>
          <ListItemIcon>
            <AccountCircle style={{ fontSize: '24px', color: 'rgba(0, 0, 0, 0.56)' }} />
          </ListItemIcon>
          {open && <ListItemText primary="Profile" />}
          {open && (
            <UnfoldMoreIcon
              style={{
                fontSize: '20px',
                color: 'rgba(0, 0, 0, 0.56)',
                transform: expandMenu ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
              }}
            />
          )}
        </ListItem>

        <Collapse in={expandMenu && open} timeout="auto" unmountOnExit orientation="vertical">
          <List component="div" disablePadding sx={{ position: 'absolute', bottom: '60px' }}>
            <ListItem button>
              <ListItemIcon><Settings /></ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem button>
              <ListItemIcon><Person /></ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon><Logout /></ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
          </List>
        </Collapse>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
