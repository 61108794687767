
/* global google */

import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import LoginPage, { TGSHeader }  from './Components/LoginPage'; // Import the LoginPage component

import Sidebar from './Components/Sidebar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import JobsPage from './Components/JobsPage';
import JobDetailsPage from './Components/JobDetailsPage.js'; 

function App() {
  const [role, setRole] = useState(null); // Store the role
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
  const [userEmail, setUserEmail] = useState(null); // New state to store email

  const handleLoginSuccess = async (token) => {
    try {
      // Decode the JWT to extract the email
      const decoded = jwtDecode(token);
      const email = decoded.email;
  
      // Check if the email domain matches the required one
      const domain = email.split('@')[1];
      if (domain !== 'thegrowthspark.com') {
        alert('Access Denied: Only users with @thegrowthspark.com email addresses are allowed.');
        
        // Reset app state to log out from your app, but do not revoke Google session
        setIsLoggedIn(false);
        setRole(null);
        return; // Stop further processing
      }
  
      // If domain is valid, fetch the user's role from the backend
      let url = `${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}`;
      const res = await axios.post(`http://${url}/get-role`, { email });
      if (res && res.data) {
        const { role } = res.data;
        if (role) {
          setRole(role); // Store the role
          setIsLoggedIn(true); // Mark as logged in
        } else {
          alert('Role not found in response');
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };
  
  
// Handle logout
// const handleLogout = (email) => {
//   setRole(null); // Clear the role
//   setIsLoggedIn(false); // Reset the login state

//   if (email && window.google && window.google.accounts && window.google.accounts.id) {
//     window.google.accounts.id.revoke(email, (done) => {
//       console.log('User session revoked for', email);
//       // You can also reset your application’s login state here
//     });
//   }

//   // Optional: Clear localStorage if you're storing tokens there
//   localStorage.clear();
// };

const handleLogout = () => {
  setRole(null); // Clear the role
  setIsLoggedIn(false); // Reset the login state
  localStorage.clear(); // Optional: Clear any stored tokens in local storage
};


  // Admin-specific action
  // const handleAdminAction = () => {
  //   if (role !== 'admin') {
  //     alert('Access denied: Admins only');
  //     return;
  //   }
  //   alert('Admin action performed');
  // };

  // // Employee-specific action
  // const handleEmployeeAction = () => {
  //   if (role !== 'employee') {
  //     alert('Access denied: Employees only');
  //     return;
  //   }
  //   alert('Employee action performed');
  // };

  // // Render the dashboard if logged in
  // if (isLoggedIn) {
  //   return (
  //     <div className="App">
  //       <h1>Role-based Dashboard</h1>
  //       <button onClick={handleLogout}>Logout</button>
  //       <button onClick={handleAdminAction}>Admin Action</button>
  //       <button onClick={handleEmployeeAction}>Employee Action</button>
  //     </div>
  //   );
  // }

  // // If not logged in, render the LoginPage component
  // // return <LoginPage onLoginSuccess={handleLoginSuccess} />;
  // return (
  //   <div>
  //     <TGSHeader />   {/* Use the top section */}
  //     <LoginPage onLoginSuccess={handleLoginSuccess} /> {/* Use the login form */}
  //   </div>
  // );

  if (!isLoggedIn) {
    return (
      <div>
        <TGSHeader />
        <LoginPage onLoginSuccess={handleLoginSuccess} />
      </div>
    );
  }

  return (
    <Router>
      <div style={{ display: 'flex' }}>
        <Sidebar handleLogout={handleLogout} />
        <div style={{ marginLeft: 240, padding: '20px', width: '100%' }}>
          <Routes>
            <Route path="/jobs" element={<JobsPage />} />
            <Route path="/jobs/:id" element={<JobDetailsPage />} /> {/* Dynamic route */}
            <Route path="/candidates" element={<h1>Candidates Page</h1>} />
            <Route path="/personas" element={<h1>Personas Page</h1>} />
            <Route path="/" element={<h1>Welcome to the Dashboard</h1>} />
          </Routes>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </Router>
  );
}

export default App;
